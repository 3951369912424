import React from "react";
import "./Registation.css";
import { Link } from "react-router-dom";

function Registation() {
  return (
    <div className="regis_info">
      <div className="heading1 abstract_mb40">Registration</div>
      
      <div className="guidelines">
        <div className="g_bold g_title">Guidelines for CompFlu-2024 registration</div>
        <ol className="g_list">
          <li>
        Registration fees will be announced on or before 1st September 2024. Please keep following this page.
          </li>
          {/*<li>
        Registration fees mentioned here are inclusive of GST.
          </li>*/}
          <li>
          Registration for the conference covers the following expenses:
            <ul>
              <li>
              Registration kit
              </li>
              <li>
              Lunch on all the conference days (16th-18th December)
              </li>
              <li>
              Gala dinner on the 17th December
              </li>
            </ul>
          </li>
         
          <li>
          Registration cost <span className="inf_red">does not include accommodation on any of the days.</span>
          </li>
          <li>
          Accommodation and dinners (except the Gala dinner)  should be booked separately and paid for by all the participants. Further details may be found on <Link to="/accomodation" className="reg_link">accomodation page</Link>.
          </li>
        </ol>
      </div>
      {/*<div className="info_div">Indian Bank Transactions</div> */}
       {/*<div className="table_info">
        <table>
          <tr>
            <th>Category</th>
            <th>Early Bird (INR)</th>
            <th>Late (INR)</th>
            <th>On Spot (INR)</th>
          </tr>
          <tr>
            <td>Faculty/Scientist/Industry Personnel</td>
            <td>15000</td>
            <td>17500</td>
            <td>20000</td>
          </tr>
          <tr>
            <td>Student (UG, PG, PhD & Post Doc)</td>
            <td>6500</td>
            <td>9000</td>
            <td>Not Allowed</td>
          </tr>
          <tr>
            <td>End Date</td>
            <td>30-Sep-2024</td>
            <td>15-Nov-2024</td>
            <td>18-Dec-2024</td>
          </tr>
        </table>
      </div> */}
      {/*<div className="info_div">Non-Indian Bank Transactions</div> */}
      <div className="table_info">
       {/* <table>
          <tr>
            <th>Category</th>
            <th>Early Bird (USD)</th>
            <th>Late (USD)</th>
            <th>On Spot (USD)</th>
          </tr>
          <tr>
            <td>Faculty/Scientist/Industry Personnel</td>
            <td>200</td>
            <td>230</td>
            <td>260</td>
          </tr>
          <tr>
            <td>Student (UG, PG, PhD & Post Doc)</td>
            <td>90</td>
            <td>120</td>
            <td>Not Allowed</td>
          </tr>
          <tr>
            <td>End Date</td>
            <td>30-Sep-2024</td>
            <td>15-Nov-2024</td>
            <td>18-Dec-2024</td>
          </tr>
        </table> */}
        {/*<div className="info_acc"><span className="inf_bold">Note:</span> Registration fees does not include accommodation. Accommodation will be provided on campus based on availability.</div>*/}
      </div> 
    </div>
  );
}

export default Registation;
