import React from "react";
import "./Dates.css";

function Dates() {
  return (
    <div className="dates-main">
      <div className="color-bg">
        <div className="heading1">Important Dates</div>
        <div className="dates">
          <div className="date-elem">
           {/* <div className="date-1">1 June, 2024</div>
            <div className="date-2">Abstract submission opens</div>*/}
            <div className="date-1">15 August, 2024</div>
            <div className="date-2">Abstract submission closes</div>
          </div>
          <div className="date-elem">
            <div className="date-1">15 September, 2024</div>
            <div className="date-2">Abstract acceptance intimation</div>
          </div>
          <div className="date-elem">
            <div className="date-1">1 September, 2024</div>
            <div className="date-2">Registration opens</div>
          </div>
          <div className="date-elem">
            <div className="date-1">30 September, 2024</div>
            <div className="date-2">Early bird registration closes</div>
          </div>
          <div className="date-elem">
            <div className="date-1">14-15 December, 2024</div>
            <div className="date-2">Pre-conference workshop</div>
          </div>
          <div className="date-elem">
            <div className="date-1">16-18 December, 2024</div>
            <div className="date-2">CompFlu-2024</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dates;
