import React from "react";
import "./Footer.css";
import { FaInstagram, FaLinkedin, FaGoogle } from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="footer-content">
        <h3>CompFlu-2024</h3>
        <p>16 - 18 December, 2024</p>
        <ul className="socials">
          {/* <li>
            <a href="#">
              <FaInstagram size={30} color="#C13584" />
            </a>
          </li>
          <li>
            <a href="#">
              <FaInstagram size={30} color="#C13584" />
            </a>
          </li> */}
          <li>
            <a href="https://www.instagram.com/compflu2024/" target="_blank">
              <FaInstagram size={30} />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/compflu2024" target="_blank">
              <i className="fa-brands fa-x-twitter fa-xl"></i>
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-bottom">
        <div className="footer-menu">
          <ul className="f-menu">
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <Link to={"/venue"}>Venue</Link>
            </li>
            <li>
              <Link to={"/#contact"}>Contact Us</Link>
            </li>
          </ul>
        </div>
        <p style={{textAlign:"center"}}>
          Website Created by: <a href="mailto:cs22btech11028@iith.ac.in">Jash Jhatakia</a> and Priyanshu Goyal. <br/>Website Maintained by:{" "}
          <a href="mailto:cs22btech11028@iith.ac.in">Jash Jhatakia</a>
        </p>
        <p>
          Copyright &copy;{" "}
          <a href="https://iith.ac.in" target="_blank">
            IIT Hyderabad
          </a>{" "}
        </p>
      </div>
    </footer>
  );
}

export default Footer;
