import React from "react";
import "./Abstract.css";
const latexTemp = require("./assets/template-CompFlu2024.zip");
const docxTemp = require("./assets/template-CompFlu2024.docx");

function Abstract() {
  return (
    <div className="abstract">
      <div className="heading1 abstract_mb40">Abstract Submission</div>
      <div className="guidelines">
        <div className="g_bold g_title">Guidelines for Oral presentation:</div>
        <ol className="g_list">
          <li>
            Each <span className="g_bold">oral presentation slot</span> is
            scheduled for <span className="g_bold">30 minutes</span>, and will
            be structured as follows:
            <ul>
              <li>
                Oral presentation:{" "}
                <span className="g_bold">
                  <span className="g_italic"> 22 minutes</span>
                </span>
              </li>
              <li>
                Q&A:{" "}
                <span className="g_bold">
                  <span className="g_italic"> 6 minutes </span>
                </span>
              </li>
              <li>
                Change over time for the next speaker:{" "}
                <span className="g_bold">
                  <span className="g_italic">2 minutes</span>
                </span>
              </li>
            </ul>
          </li>
          <li>
            The speaker will be given a timer notice first at 20 minutes, and
            again at 22 minutes.{" "}
            <span className="g_bold">
              Speakers are requested to plan their presentations accordingly,
              and are expected to stop precisely at 22 minutes.
            </span>{" "}
            The session chairs will adhere to the time limit strictly.
          </li>
          <li>
            Regardless of whether the abstract was invited by the chairs or
            contributed by the speaker{" "}
            <span className="g_italic">suo moto</span>, the guidelines for oral
            presentations remain the same.
          </li>
          <li>
            <span className="g_bold">
              All speakers are requested to check their presentations for system
              compatibility at the venue during the break preceding their
              respective sessions.
            </span>{" "}
            Volunteers will be present at each of the seminar/conference rooms
            to help with this.
          </li>
        </ol>
        <div className="g_bold g_title">Guidelines for Flash presentation:</div>
        <ol className="g_list">
          <li>
            All participants whose abstracts have been shortlisted under flash
            presentations{" "}
            <span className="g_bold">
              will need to be prepared with both a flash talk and a poster
            </span>
            . The flash talk will be presented along with the scheduled oral
            presentations, while the posters will be presented along with the
            scheduled poster presentations.
          </li>
          <li>
            Each <span className="g_bold">flash talk slot</span> is scheduled
            for <span className="g_bold">5 minutes</span>, and will be
            structured as follows:
            <ul>
              <li>
                Flash talk:{" "}
                <span className="g_bold">
                  <span className="g_italic">4 minutes</span>
                </span>
              </li>
              <li>
                Change-over time for the next speaker:{" "}
                <span className="g_bold">
                  <span className="g_italic">1 minute</span>
                </span>
              </li>
              <li>
                The presentation should have a maximum of 4 slides (including
                the title slide).
              </li>
            </ul>
          </li>
          <li>
            The speaker will be given a timer notice at 4 minutes.{" "}
            <span className="g_bold">
              {" "}
              Speakers are requested to plan their flash talks accordingly, and
              stop precisely at 4 minutes.
            </span>
          </li>
          <li>
            To enable smooth conduct of the proceedings, the organizing team
            will be collecting and compiling flash talk presentations from the
            speakers in advance. Further information in this regard will be
            provided closer to the conference date.
          </li>
          <li>
            No questions/discussion will be allowed for flash talks. Speakers
            may engage with the audience during their respective poster
            presentations.
          </li>
          <li>
            For the poster, participants should follow the guidelines for
            preparing posters as listed below.
          </li>
        </ol>
        <div className="g_bold g_title">
          Guidelines for Poster presentation:
        </div>
        <ol className="g_list">
          <li>
            The poster should be printed at{" "}
            <span className="g_bold">A0 size</span> (viz., portrait mode by
            default).
          </li>
          <li>
            The presenters should identify the poster number, and put up their
            poster in the designated area.
          </li>
          <li>
            The time slots for the posters will be announced in advance, and the
            presenters are expected to put up and promptly remove the posters
            before and after the time slots.
          </li>
        </ol>
      </div>
      <div className="abstract_docs">
        <div className="heading4a tech_align">
          For LaTeX Template :-{" "}
          <a
            className="abstract_link"
            href={latexTemp}
            download={"template-Compflu2024.zip"}
          >
            Click Here
          </a>
        </div>
        <div className="heading4a tech_align">
          For Word Template :-{" "}
          <a
            className="abstract_link"
            href={docxTemp}
            download={"template-CompFlu2024.docx"}
          >
            Click Here
          </a>
        </div>
        <div className="btn-home-disabled abstract_button">
          Abstract Submission Closed
        </div>
      </div>
    </div>
  );
}

export default Abstract;
