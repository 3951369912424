import React from 'react';
import './Accomodation.css';

function Accomodation() {
  return (
    <div className='accomodation'>
      <div className="heading1">Accomodation</div>
      <div className="acc_para">
        Accommodation will be made available on a first-come, first-served basis at nominal charges for participants as follows:
        <br />
        <br />
        <ul className="g_list_1">
          <li>Faculty/Scientist participants: at the IIT Hyderabad guest house</li>
          <li>Student participants: at the IIT Hyderabad hostels</li>
        </ul>
        <br />
        <br />
        Further details about accommodation and payment will be provided on this page after 30th September.
        If participants wish to stay off-campus, suitable suggestions for hotels near IIT Hyderabad will also be provided on this page.
      </div>
    </div>
  );
}

export default Accomodation;
